export enum KeyPaymentType {
  CASH = 'cash',
  CHECK = 'check',
  CARD = 'card',
  SEPA = 'sepa',
  SEPA_STRIPE = 'sepa_debit',
  CHECK_VACATION = 'check-vacation',
  COUPON_SPORT = 'coupon-sport',
  MIXT_ONLIVE = 'mixt-onlive'
}

export const KeyPaymentTypeWithName = [
  { name: 'Paiement en espèces', value: 'cash'},
  { name: 'Paiement par chèque', value: 'check'},
  { name: 'Paiement CB', value: 'card'},
  { name: 'Paiement en ligne CB', value: 'card_online'},
  { name: 'Paiement en ligne Sepa', value: 'sepa_debit'},
  { name: 'Chèques-vacances (ANCV)', value: 'check-vacation'},
  { name: 'Coupons Sport', value: 'coupon-sport'},
  { name: 'Mixte sur place', value: 'mixt-onlive'},
] 

export const KeyPaymentTypeOrderInvoiceWithName = [
  { name: 'Prélèvement en ligne CB', value: 'CARD'},
  { name: 'Prélèvement en ligne Sepa', value: 'SEPA'},
  { name: 'Paiement en espèces', value: 'CASH'},
  { name: 'Paiement par chèque', value: 'CHECK'},
  { name: 'Paiement CB sur place', value: 'CARD-ON-PLACE'},
  { name: 'Chèques-vacances (ANCV)', value: 'CHECK-VACATION'},
  { name: 'Chèques-activités', value: 'CHECK-ACTIVITY'},
  { name: 'Coupons Sport', value: 'COUPON-SPORT'},
  { name: 'Mixte sur place', value: 'MIXT-ONLIVE'},
  { name: 'Gratuit', value: 'FREE'},
  { name: 'Virement', value: 'TRANSFER'},
  { name: 'Pole Emploi', value: 'POLEEMPLOI'},
] 

export const KeyPaymentTypeRefund = [
  { name: 'Paiement en espèces', value: 'CASH'},
  { name: 'Paiement par chèque', value: 'CHECK'},
  { name: 'Virement', value: 'TRANSFER'},
] 